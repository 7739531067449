import React from "react";

const CardToile = ({ toile }) => {
  return (
    <li
      className="card-container"
      key={toile.id}
      alt={`${toile.title}`}
    >
        <img
          src={toile.image}
          alt={"Toile" + `${toile.title}`}
        />
      
        <div className="footer-card">
            <div className="info-toile">
                <h3>{toile.title}</h3> 
                <span>
                    <p>{`${toile.dimension}` + "cm" +  ", "}</p>
                    <p className="toileDate">{" " + `${toile.date}`}</p>
                </span>
                
            </div>
      </div>
    </li>
  );
};

export default CardToile;