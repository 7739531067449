import React from "react";

const CardPrint = ({ Print }) => {
  return (
    <li
      className="card-container"
      key={Print.id}
      alt={`${Print.localisation}`}
    >
        <img
          src={Print.image}
          alt={"Print" + `${Print.localisation}`}
        />
      
        <div className="footer-card">
            <div className="info-Toile">
                <h3>{Print.localisation}</h3> 
                <h3>{Print.title}</h3> 
                
                
            </div>
      </div>
    </li>
  );
};

export default CardPrint;