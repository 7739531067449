import React, { useEffect, useState } from "react";
import ReactSwipe from 'react-swipe';

import { abstract_data } from "../data/collab_data";
import CardPrint from "../components/CardPrint";

const Collab = () => {
  const [Print, setPrint] = useState([]);
  
  useEffect(() => {
    setPrint(abstract_data);
  }, [Print]);

  let reactSwipeEl;

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <main className="toile">

<h1>Fluctus Nebulae</h1>

      <ul className="wall-print">
        {Print.map((Print, key) => {
          return <CardPrint Print={Print} key={Print.id} />;
        })}
      </ul>
      <div className="collab-text">
        <div className="text-fr">
          <p>
            <b>Fluctus Nebulae</b> est une collaboration entre les artistes  <i>Antoine Devictor</i> et <a href="https://alextran.ca/"> <i>Alex Tran</i></a>.
          </p>
          <br/>
          <p>
            Inspirés par les images déroutantes de l'espace prises par les télescopes Webb et Hubble, nous avons cherché à créer notre propre cosmos. La danse des encres à alcool dans la résine donne lieu à une symphonie visuelle qui se traduit par des compositions infinies et imprévisibles. Ces explosions, suspendues dans le temps et l'espace, sont cristallisées dans la résine tout en restant fluides et dynamiques. Tout comme l'observation des étoiles est une forme de voyage dans le temps, l'observation de ces structures nous rend témoins de la période éphémère qui précède leur immobilité perpétuelle.
          </p>
          
          <div className="text-right">
            <p>
              Disponible à l'achat, contactez <b>antoinedevictor.art@gmail.com</b> pour plus d'informations.
            </p>
            <br/>
            <p>
              16 x 24" (édition limitée à 5 exemplaires) | 500$
            </p>
            <br/>
            <p>
              8 x 12" (édition limitée à 20) | 100$
            </p>
            <br/>
            <p>
              Papier d'archives Hahnemule Museum Etching - 350 g/m² - 100% coton
            </p>
            <br/>
            <p>
              Les tirages comprennent une bordure blanche et sont estampillés (recto), signés et numérotés (verso) à la main.
            </p>
          </div>
          
        </div>
        <div className="text-en">
          <p>
          <b>Fluctus Nebulae</b> is a collaboration between artists <i>Antoine Devictor</i> and <a href="https://alextran.ca/"> <i>Alex Tran</i></a>.
          </p>
          <br/>
          <p>
            Inspired by the bewildering images of space taken by the Webb and Hubble telescopes, we sought to create our own cosmos. As alcohol-based inks dance within resin, a visual symphony erupts, resulting in endless and unpredictable compositions. These explosions, suspended in time and space, are crystallized in resin yet remain fluid and dynamic. Just as stargazing is a form of time travel, observing these structures makes us witnesses of the ephemeral period just before their perpetual stillness.
          </p>
        
          <div className="text-right">
            <p>
              Available for purchase, contact <b>antoinedevictor.art@gmail.com</b> for more information.
            </p>
            <br/>
            <p>
              16 x 24” (limited edition of 5) | 500$
            </p>
            <br/>
            <p>
              8 x 12” (limited edition of 20) | 100$
            </p>
            <br/>
            <p>
              Hahnemule Museum Etching archival paper · 350 gsm · 100% cotton
            </p>
            <br/>
            <p>
              Prints include a white border and are stamped (front), signed, and numbered (back) by hand.
            </p>
          </div>
          
        </div>
      </div>
      
      <div className="slider-container">
      <ReactSwipe
        className="carousel"
        swipeOptions={{ 
          continuous: true,
          auto:3000,
        }}
        ref={el => (reactSwipeEl = el)}
      >
        <div>
          <img className="img-slider-collab" src={process.env.PUBLIC_URL + "/img/collab/slider/a.webp"} alt="" />
        </div>
        <div>
          <img className="img-slider-collab" src={process.env.PUBLIC_URL + "/img/collab/slider/Stamp_1.webp"} alt="" />
        </div>
        <div>
          <img className="img-slider-collab" src={process.env.PUBLIC_URL + "/img/collab/slider/Stamp_2.webp"} alt="" />
        </div>
      </ReactSwipe>
      <button className="cta-next" onClick={() => reactSwipeEl.next()}><span></span></button>
      <button className="cta-preview" onClick={() => reactSwipeEl.prev()}><span></span></button>
    </div>
    </main>

    
  );
};

export default Collab;