export const abstract_data = [
     // {
     //     id: "A022",
     //     title: "Patate",
     //     image: `${process.env.PUBLIC_URL + "/img/abstract/papate.webp"}`,
     //     technique: "Encre à alcool/toile",
     //     dimension: "76 x 51",
     //     date:"2021",
     // },
    {
        id: "C001",
        localisation: "230903.19.32.03",
        image: `${process.env.PUBLIC_URL + "/img/collab/a-noframe-web.webp"}`,
        technique: "Encre à alcool/Résine",
        title: "FN-i.212.86.4",
        date:"2023",
    },
    {
        id: "C002",
        localisation: "231022.18.18.09",
        image: `${process.env.PUBLIC_URL + "/img/collab/b-noframe-web.webp"}`,
        technique: "Encre à alcool/Résine",
        title: "FN-ii.5.82.47",
        date:"2023",
    },
    {
        id: "C003",
        localisation: "230903.19.32.03",
        image: `${process.env.PUBLIC_URL + "/img/collab/c-noframe-web.webp"}`,
        technique: "Encre à alcool/Résine",
        title: "FN-i.212.86.4",
        date:"2023",
    },
  ];