import React, { useEffect, useState } from "react";

import { abstract_data } from "../data/abstract_data";
import CardToile from "../components/CardToile";

const Home = () => {
  const [toile, setToile] = useState([]);
  
  useEffect(() => {
    setToile(abstract_data);
  }, [toile]);

  return (
    <main className="toile">


      <ul className="wall-toile">
        {toile.map((toile, key) => {
          return <CardToile toile={toile} key={toile.id} />;
        })}
      </ul>
    </main>
  );
};

export default Home;