import React from "react";

const About = () => {
  return (
    <div className="about">
      {/*<h1>ABOUT TEST</h1>*/}
      <div className="aboutSection">
          <picture>
            <img
                src={process.env.PUBLIC_URL + "/img/photo_about.webp"}
                alt="Photo de l'artiste"
            />
          </picture>
          <div className="aboutSection_text">
            <p>
                Né dans le sud de la France en 1990, dans une famille d’artistes, Antoine trouve sa passion dans les arts plastiques dès l’âge de 6 ans. Aujourd'hui, il réside à Montréal, au Canada.
            <br /><br />
            </p>

            <p>
            À travers son art, Antoine explore la matérialisation de la condition humaine et son impact sur notre réalité, telles des ondes se propageant à la surface de l'eau. Sa pratique de l'abstraction géométrique lui permet de traduire cette vision unique, au travers de ses yeux de daltonien.
                <br /><br />
            </p>
            <p>
                Ses œuvres s’ancrent dans une démarche expérimentale. En mêlant différentes techniques artistiques, il cherche à créer des résultats visuels saisissants et à repousser continuellement les limites des distorsions visuelles, pour trouver de nouveaux moyens de surprendre, de divaguer et de s’échapper.
                <br /><br />
            </p>
            <p>
                Antoine cherche à créer de la nouveauté tout en laissant beaucoup de place à l’aléatoire grâce à son utilisation de l’encre à alcool qui lui permet de créer des motifs organiques.<br />
                Chaque œuvre est une fenêtre sur un monde onirique, presque irréel, dont seul le spectateur a le contrôle. <br />
                C’est l’artiste qui interpelle le public en lui donnant une liberté d’interprétation et qu’il puisse faire ce qu’il veut.
                <br /><br /><br />
            </p>
            <p>
            Contact : <a className="mail" href="mailto:antoinedevictor.art@gmail.com">antoinedevictor.art@gmail.com</a>
            </p>

          </div>
      </div>
    </div>
  );
};

export default About;