import React from "react";
import {
  HashRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import Home from "../pages/Home";
import About from "../pages/About";
import Collab from "../pages/Collab";
import Header from "../components/Header";
import ScrollToTop from "../components/scrollToTop";

const index = () => {
  return (
    <React.StrictMode>
    <Router>
      <ScrollToTop />
      <Header />
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/collaboration" element={<Collab/>} />
        <Route path='/redirect-page' element={ <Navigate to="/error-page" /> }/>
      </Routes>
    </Router>
    </React.StrictMode>
  );
};

export default index;