import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";

const Header = () => {
  const [header, setHeader] = useState(false);
  const [showLinks, setShowLinks] = useState(false);

  const handleShowLinks = () => {
    setShowLinks(!showLinks);
  };

  const changeScrollHeader = () => {
    if (window.scrollY >= 85) {
      setHeader(true);
    } else {
      setHeader(false);
    }
  };

  window.addEventListener("scroll", changeScrollHeader);
  return (
    <header className={header ? "header active" : "header"}>
      <div className="container_logo">
      <img
          src={process.env.PUBLIC_URL + "/img/logo_froid.png"}
          alt="logo"
        />
      </div>
      <nav>
        <ul className={showLinks ? "show-bar" : null}>
          <li>
            <NavLink
              exact
              to="/"
              title="Home"
              activeClassName="header-activ"
              onClick={() => {
                setShowLinks(false);
              }}
            >
              <h3>WORK</h3>
            </NavLink>
          </li>
          <li>
          <NavLink
              exact
              to="Collaboration"
              title="Collab"
              activeClassName="header-activ"
              onClick={() => {
                setShowLinks(false);
              }}
            >
              <h3>COLLABORATION</h3>
            </NavLink>
          </li>
          <li>
          <NavLink
              exact
              to="About"
              title="About"
              activeClassName="header-activ"
              onClick={() => {
                setShowLinks(false);
              }}
            >
              <h3>ABOUT</h3>
            </NavLink>
            {/* <Link
              smooth
              to="/#about-us"
              onClick={() => {
                setShowLinks(false);
              }}
            >
              <h3>ABOUT</h3>
            </Link> */}
          </li>
          
          {/* <li>
            <NavLink
              exact
              to="/Events"
              title="Events"
              activeClassName="header-activ"
              onClick={() => {
                setShowLinks(false);
              }}
            >
              <h3>EVENTS</h3>
            </NavLink>
          </li> */}
          <li>
            <a
              href="https://antoinedevictor.bigcartel.com/?fbclid=IwAR1FkAzqu8KlTeY9kGl_W2Pkxj2FYk2XyLBnk3kY0rGFAmm2Z5ZHfQnXOBI"
              rel="noopener noreferrer"
              target="_blank"
              title="Shop"
              onClick={() => {
                setShowLinks(false);
              }}
            >
              <h3>SHOP</h3>
            </a>
          </li>

          <li>
          <a
              href=" https://www.instagram.com/ad.sgn/?hl=fr"
              target="_blank"
              rel="noopener noreferrer"
              title="Instagram"
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a
              href="https://www.facebook.com/a.d.sgn7/"
              target="_blank"
              rel="noopener noreferrer"
              title="Facebook"
            >
              <i className="fab fa-facebook-square"></i>
            </a>
           
          </li>
        </ul>
        <button className="navbar-burger" onClick={handleShowLinks}>
          <span className={showLinks ? "cross-bar" : null}></span>
        </button>
      </nav>
    </header>
  );
};

export default Header;